<template>
  <v-app>
    <v-app-bar
      app
      elevation="0"
      color="white"
    >
      <v-row align="center" justify="space-between">
        <v-col cols="auto" v-if="$vuetify.breakpoint.xs">
          <v-btn @click="submenuMobile = !submenuMobile" :ripple="false" text rounded class="px-0" style="min-width: fit-content !important;">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-img src="@/assets/img/murcielago-logotipo.png" contain class="logo-header"></v-img>
        </v-col>
        <v-col cols="auto" v-if="$vuetify.breakpoint.xs">
          <v-icon @click="showCart = !showCart">mdi-basket-outline</v-icon> {{cart.length}}
        </v-col>
        <v-col class="pl-12 d-none d-sm-block">
            <router-link to="/" class="header-links d-inline-block mr-7">Inicio</router-link>
            <router-link to="/historia" class="header-links d-inline-block mr-7">Historia</router-link>
            <router-link to="/tienda" class="header-links d-inline-block mr-7">Tienda</router-link>
        </v-col>
        <v-col class="text-right d-none d-sm-block">
          <div class="d-inline-block mr-4">
            <div class="d-inline-block px-9 py-1 pointer subtitles" style="border: 1px solid #A06A4C; border-radius: 20px;" @click="modalEdad = true">
              Enviar a <span class="px-1">|</span> <span class="bold">
                <template v-if="!infoEstado.considerarPrecioPorCiudad">{{infoEstado.name}} </template>
                <template v-else>{{nombreCiudad}}</template></span>
            </div>
          </div>
          <v-icon @click="showCart = !showCart">mdi-basket-outline</v-icon> {{cart.length}}

        </v-col>
      </v-row>
    </v-app-bar>

    <v-expand-transition>
      <v-container v-if="submenuMobile" class="menu-mobile">
        <v-row justify="center">
          <v-col cols="auto">
            <router-link to="/" class="header-links">Inicio</router-link>
          </v-col>
          <v-col cols="auto" class="px-12">
            <router-link to="/historia" class="header-links">Historia</router-link>
          </v-col>
          <v-col cols="auto">
            <router-link to="/tienda" class="header-links">Tienda</router-link>
          </v-col>
          <v-col cols="12" class="pb-7">
            <v-row no-gutters justify="center">
              <v-col cols="auto">
                <div class="d-inline-block mr-4">
                  <div class="d-inline-block px-9 py-1 pointer subtitles" style="border: 1px solid #A06A4C; border-radius: 20px;" @click="modalEdad = true">
                    Enviar a <span class="px-1">|</span> <span class="bold"><template>{{infoEstado.name}} </template><template></template></span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-expand-transition>

    

    <v-navigation-drawer
      app
      right
      temporary
      v-model="showCart"
      width="350"
    >
      <v-container fluid class="px-0 py-0">
        <div>
          <v-row class="my-0" style="height: 81px;" align="center">
            <v-col class="px-6">
              <h1>Cesta de compra</h1>
            </v-col>
            <v-col cols="auto" class="px-6">
              <v-icon class="pointer" @click="showCart = false">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <div style="height: calc(100vh - 312px); overflow: auto;">
          <template v-if="emptyCart">
            <v-row style="min-height: calc(100vh - 312px)" class="my-0 py-3" align="center">
              <v-col class="text-center">
                <span>Tu cesta está vacía</span>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row style="min-height: calc(100vh - 312px)" class="my-0 py-3">
              <v-col class="px-6">
                <v-card elevation="0" v-for="(item, i) in cart" :key="'item-card-'+i">
                  <v-row class="my-0">
                    <v-col cols="auto">
                      <v-img :src="'https://content.elmurcielagotequileria.com/'+item.presentacion.producto.cover.path" width="60" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col>
                      <span class="d-block">
                        {{item.presentacion.producto.categoria}} {{item.presentacion.producto.nombre}}
                      </span>
                      <span class="d-block">Clase: {{item.presentacion.producto.clase.name}}</span>
                      <span class="d-block">Contenido: {{item.presentacion.contenido}}</span>
                      <span class="d-block">Precio: ${{numberWithCommas(item.presentacion.precio)}}</span>
                      <span class="d-block">Cantidad: 
                        <v-btn icon @click="disminuirItem(i)"><v-icon>mdi-minus</v-icon></v-btn> 
                        <input v-model="item.cantidad" class="d-inline-block text-center" style="width: 25px" disabled />
                        <v-btn icon @click="aumentarItem(i)"><v-icon>mdi-plus</v-icon></v-btn>
                      </span>
                    </v-col>
                    <v-col cols="auto">
                      <v-icon @click="removeItem(i)">mdi-delete</v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </div>
        <div style="background-color: #EFECE8;">
          <v-row class="my-0 text-center" align="center" style="height: 231px;">
            <v-col>
              <div class="mx-auto" style="max-width: 260px">
                <v-text-field label="Código de descuento" append-outer-icon="mdi-send-circle-outline"></v-text-field>
              </div>
              <span class="d-block">Subtotal ${{numberWithCommas(total)}}</span>
              <span class="d-block">Envio ${{numberWithCommas(precioEnvio)}}</span>
              <div>
                <v-btn to="/cesta" width="260" elevation="0">Cesta de compra</v-btn>
              </div>
              <template v-if="cart.length > 0">
                <div class="mt-3">
                  <v-btn to="/checkout" width="260" elevation="0" color="#93A898">Proceder a la compra</v-btn>
                </div>
              </template>
              <template v-else>
                <div class="mt-3">
                  <v-btn to="/tienda" width="260" elevation="0" color="#A06A4C">Ir a la Tienda</v-btn>
                </div>
              </template>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-navigation-drawer>
    <v-snackbar
        v-model="snackbarEliminado"
        top
        color="#722929"
    >
        Eliminado del carrito

        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="snackbarEliminado = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
    <v-main>
      <router-view v-on:update="updateCart" :cart="cart" :total="total" :envio="precioEnvio" :precioFinal="precioFinal" :ciudad="nombreCiudad" :estado="infoEstado" />
    </v-main>

    <v-footer color="#1a1a1a">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="3" class="text-center text-sm-left">
            <v-img src="@/assets/img/murcielago-logo-color-01.svg" max-width="120px" style="filter: brightness(0) invert(1);" class="mx-auto mx-sm-0"></v-img>
          </v-col>
          <v-col cols="12" sm="4" offset-sm="1" class="text-center white--text">
            <h2>Información</h2>
            <router-link to="/" class="d-block white--text">Envíos y Devoluciones</router-link>
            <router-link to="/" class="d-block white--text">Términos y Condiciones</router-link>
            <router-link to="/" class="d-block white--text">Política de Privacidad</router-link>
            <a href="https://www.iyaax.net/fe/sitio/facturar.html?ELMURCIELAGO" target="_blank" class="d-block white--text">Facturación</a>
          </v-col>
          <v-col cols="12" sm="3" offset-sm="1" class="text-center white--text">
            <h2>Formas de Pago</h2>
            <v-img src="@/assets/img/formas-de-pago-03.svg" width="43" class="d-inline-block mx-3"></v-img>
            <v-img src="@/assets/img/formas-de-pago-04.svg" width="43" class="d-inline-block mx-3"></v-img>
            <v-img src="@/assets/img/formas-de-pago-05.svg" width="43" class="d-inline-block mx-3"></v-img>
            <v-img src="@/assets/img/formas-de-pago-06.svg" width="43" class="d-inline-block mx-3"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <v-dialog
      v-model="modalEdad"
      persistent
      class="px-0"
      max-width="900"
      overlay-opacity="0.9"
      overlay-color="black"
    >
      <v-card class="modal-card" style="overflow: hidden">
        <v-container class="py-0">
          <v-row class="my-0" style="height: 500px;" align="center">
            <v-col cols="12" sm="6" class="px-0 py-0">
              <div style="position: relative;">
                <video src="@/assets/Intro.mp4" class="video-modal" muted autoplay playsinline loop></video>
                <div style="position: absolute; width: 100%; height: 100%; top: 0; background-color: rgba(0,0,0,.2);z-index: 2">
                </div>
                <div class="div-logo-text">
                  <v-img class="logo-modal mx-auto d-block" src="@/assets/img/murcielago-logo-color-02.svg"></v-img>

                  <div class="text text-center d-none d-sm-block"><span class="white--text bold">Los mejores productos del agave mexicano, catado por grandes Mestros Tequileros.</span></div>
                </div>
                
              </div>

            </v-col>
            <v-col cols="12" sm="6" class="text-center px-0">

             <v-form lazy-validation ref="formAge" v-model="valid">
                <div class="px-4 px-sm-12">
               
                  <v-img src="@/assets/img/murcielago-logo-color-01.svg" max-width="100px" v-if="$vuetify.breakpoint.xs" class="mx-auto d-none d-sm-block"></v-img>
                  <span class="titles bold">
                    ¿Eres mayor de edad?
                  </span>
               
                  <v-radio-group v-model="mayor" @change="changeDesition">
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-radio
                          :label="'Si'"
                          :value="'Si'"
                          color="#ADC332"
                        ></v-radio>
                      </v-col>
                      <v-col cols="auto">
                        <v-radio
                          :label="'No'"
                          :value="'No'"
                          color="gray"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
               
                  <h5 class="subtitles mb-5">¿A qué lugar de México deseas enviar tu pedido?</h5>
               
                  <v-row class="my-0">
                    <v-col class="py-0">
                      <v-select :items="listaEstados" color="black" item-color="#ADC332" v-model="estado" label="Selecciona tu estado" @change="validateEstados" :disabled="disabledFields">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="my-0" style="height: 70px">
                    <v-col class="py-0">
                      <v-select v-if="listaCiudades != false" :items="listaCiudades" v-model="ciudad" label="Selecciona tu ciudad" color="black" item-color="#ADC332" @change="validateEstados" :disabled="disabledFields"></v-select>
                    </v-col>
                  </v-row>
               
                  <v-row>
                    <v-col>
                      <v-btn color="#ADC332" block elevation="0" @click="validarIngreso()" :disabled="disabled">Ingresar al sitio</v-btn>

                      <a href="https://www.iyaax.net/fe/sitio/facturar.html?ELMURCIELAGO" target="_blank" class="d-block black--text" style="color: black !important">Facturación</a>
                    </v-col>
                  </v-row>
               
                  <v-row class="line-height-general">
                    <v-col>
                      <span class="small-texts">Al ingresar al sitio aceptas nuestros Términos y Condiciones, así como nuestra Politica de Privacidad</span>
                    </v-col>
                  </v-row>
                </div>
             </v-form>

            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <div style="position: fixed; bottom: 30px; right: 30px; border-radius: 50%;">
      <v-btn rounded fab color="green" href="https://wa.me/+5218135829919" target="_blank">
        <v-icon color="white" size="36">mdi-whatsapp</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import Vue from 'vue'
import VueSession from 'vue-session'
Vue.use(VueSession, {persist: true})

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    cart: [],
    showCart: false,
    modalEdad: true,
    mayor: null,
    estados: [],
    estado: null,
    ciudades: [],
    ciudad: null,
    snackbarEliminado: false,
    valid: true,
    disabled: true,
    disabledFields: false,
    //menu mobile
    submenuMobile: false
  }),
  computed: {
    total: function () {
      let total = 0;

      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];

        total = total + (element.cantidad * element.presentacion.precio);
        
      }

      return total;
    },
    emptyCart: function () {
      if(this.cart.length == 0){
        return true
      }else{
        return false
      }
    },
    listaEstados: function () {
      var lista = [];
      for (let index = 0; index < this.estados.length; index++) {
        const element = this.estados[index];
        lista.push({
          text: element.name,
          value: element._id
        })
        
      }
      return lista;
    },
    listaCiudades: function () {
      var ciudades = [];
      if(this.estado != null){
        for (let index = 0; index < this.estados.length; index++) {
          const element = this.estados[index];
          if(element.considerarPrecioPorCiudad){
            for (let n = 0; n < this.ciudades.length; n++) {
              const city = this.ciudades[n];
              if(city.estado._id == this.estado){
                ciudades.push({
                  text: city.nombre,
                  value: city._id
                })
              }
            }

            return ciudades;
          }
        }
      }
      return false;
    },
    infoEstado: function () {
      for (let index = 0; index < this.estados.length; index++) {
        const element = this.estados[index];

        if(element._id == this.estado){
          return element
        }
        
      }

      return false;
    },
    nombreCiudad: function () {
      for (let index = 0; index < this.ciudades.length; index++) {
        const element = this.ciudades[index];

        if(element._id == this.ciudad){
          return element.nombre
        }
        
      }
      return false;
    },
    precioEnvio: function () {
      if(!this.modalEdad){
        if(this.listaCiudades == false){
          //No hay ciudades para este estado, asi que hay que calcular el precio por Peso

          if(this.infoEstado != false){
            if(this.peso < 1000){
              return this.infoEstado.precio1;
            }

            if(this.peso < 2000){
              return this.infoEstado.precio2;
            }

            if(this.peso < 3000){
              return this.infoEstado.precio3;
            }

            if(this.peso < 4000){
              return this.infoEstado.precio4;
            }

            if(this.peso < 5000){
              return this.infoEstado.precio5;
            }

            if(this.peso < 6000){
              return this.infoEstado.precio6;
            }

            if(this.peso < 7000){
              return this.infoEstado.precio7;
            }

            if(this.peso < 8000){
              return this.infoEstado.precio8;
            }

            if(this.peso < 9000){
              return this.infoEstado.precio9;
            }

            if(this.peso < 10000){
              return this.infoEstado.precio10;
            }

            if(this.peso > 10000){

              var sobrepeso = this.peso - 10000;

              sobrepeso = sobrepeso / 1000;

              sobrepeso = Math.ceil(sobrepeso);

              if(this.peso > 30000){
                sobrepeso = this.peso - 30000;
                sobrepeso = sobrepeso / 10000;
                sobrepeso = Math.ceil(sobrepeso)

                return parseInt(this.infoEstado.precio10) + (20 * parseInt(this.infoEstado.precio11)) + (sobrepeso * parseInt(this.infoEstado.precio12));
              }

              return parseInt(this.infoEstado.precio10) + (sobrepeso * parseInt(this.infoEstado.precio11));
            }
          }
        }else{
          for (let index = 0; index < this.ciudades.length; index++) {
            const element = this.ciudades[index];
            if(element._id == this.ciudad){
              return parseInt(element.precio)
            }
          }
        }
      }
      return false
    },
    peso: function () {
      let gramos = 0;
      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];
        gramos = gramos + (parseInt(element.presentacion.peso * element.cantidad));
      }
      if(gramos < 1000){
        return 1000
      }else{
        return gramos;
      }
    },
    precioFinal: function (){
      return parseInt(this.precioEnvio) + parseInt(this.total);
    }
  },
  watch: {
    estado: function () {
      this.ciudad = null
    },
    ciudad: function () {

    },
    $route: function (){
      this.$vuetify.goTo(0);
    }
  },
  methods: {
    numberWithCommas: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    disminuirItem: function (i) {
      let carrito = [];
      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];
        if(index == i){
          if(element.cantidad > 1){
            element.cantidad--
          }
        }

        carrito.push(this.cart[index]);
      }

      this.cart = carrito;
      this.$session.set('emcart',this.cart);
    },
    aumentarItem: function (i) {
      let carrito = [];
      for (let index = 0; index < this.cart.length; index++) {
        const element = this.cart[index];
        if(index == i){
          if(element.cantidad < parseInt(element.presentacion.unidadesDisponibles)){
            element.cantidad++
          }
        }

        carrito.push(this.cart[index]);
      }

      this.cart = carrito;
      this.$session.set('emcart',this.cart);
    },
    updateCart: function () {
      this.cart = this.$session.get('emcart');
    },
    removeItem: function (id) {
      let carrito = [];
      for (let index = 0; index < this.cart.length; index++) {
        if(index != id){
          carrito.push(this.cart[index]);
        } 
      }

      this.cart = carrito;
      this.snackbarEliminado = true;
      this.$session.set('emcart',this.cart);
    },
    validarIngreso: function () {
      if(this.mayor == 'Si'){
        if(this.estado != null){
          if(this.listaCiudades == false){
            this.modalEdad = false;
          }else{
            if(this.ciudad != null){
              this.modalEdad = false;
            }else{
              alert('No se ha seleccionado ciudad');
              //No se ha seleccionado ciudad
            }
          }
        }else{
          alert('No se ha seleccionado estado');
          // No se ha seleccionado estado
        }
      }else{
        alert('No eres mayor de edad');
        // No eres mayor de edad :(
      }
    },
    validateEstados: function (){
        if(this.estado != null){
          if(this.listaCiudades == false){
            // this.modalEdad = false;
            this.disabled = false
          }else{
            if(this.ciudad != null){
              this.disabled = false
              // this.modalEdad = false;
            }else{
              // alert('No se ha seleccionado ciudad');
              //No se ha seleccionado ciudad
              this.disabled = true
            }
          }
        }else{
          // alert('No se ha seleccionado estado');
          // No se ha seleccionado estado
          this.disabled = true
        }
    },
    changeDesition: function (){
      if(this.mayor == 'Si'){
        this.disabledFields = false

        if(this.estado != null){
          if(this.listaCiudades == false){
            // this.modalEdad = false;
            this.disabled = false
          }else{
            if(this.ciudad != null){
              this.disabled = false
              // this.modalEdad = false;
            }else{
              // alert('No se ha seleccionado ciudad');
              //No se ha seleccionado ciudad
              this.disabled = true
            }
          }
        }else{
          // alert('No se ha seleccionado estado');
          // No se ha seleccionado estado
          this.disabled = true
        }
      }else{
        // alert('No eres mayor de edad');
          this.disabled = true
          this.disabledFields = true
      }
    }
  },
  beforeMount: function () {
    if(this.$session.get('emcart') === undefined){
      this.$session.set('emcart', []);
    }

    this.cart = this.$session.get('emcart');

    fetch('https://content.elmurcielagotequileria.com/api/collections/get/Estados', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          sort:{
            name: 1
          },
          populate: 1, // resolve linked collection items
      })
    })
    .then(res=>res.json())
    .then(res => this.estados = res.entries);

    fetch('https://content.elmurcielagotequileria.com/api/collections/get/Ciudades', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          sort:{
            name: 1
          },
          populate: 1, // resolve linked collection items
      })
    })
    .then(res=>res.json())
    .then(res => this.ciudades = res.entries);
  }
};
</script>
