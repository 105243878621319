import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify( {
    vuetify:{
        customVariables: ['~/assets/scss/variables.scss'],
    }
});
