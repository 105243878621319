import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/tienda',
    name: 'Tienda',
    component: () => import('../views/Tienda.vue')
  },
  {
    path: '/tienda/tequilas',
    name: 'Tienda Tequilas',
    component: () => import('../views/Tienda.vue')
  },
  {
    path: '/tienda/mezcales',
    name: 'Tienda Mezcales',
    component: () => import('../views/Tienda.vue')
  },
  {
    path: '/tienda/:page',
    name: 'Tienda Page',
    component: () => import('../views/Tienda.vue')
  },
  {
    path: '/pedido/:id',
    name: 'Pedido',
    component: () => import('../views/Pedido.vue')
  },
  {
    path: '/tienda/productos/:url',
    name: 'Producto',
    component: () => import('../views/Producto.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/cesta',
    name: 'Cesta',
    component: () => import('../views/Cesta.vue')
  },
  {
    path: '/historia',
    name: 'Historia',
    component: () => import('../views/Historia.vue')
  },
  // {
  //   path: '/sitemap',
  //   name: 'Sitemap',
  //   component: () => import('../views/Sitemap.vue')
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
